/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import moment from "moment";
const userName = localStorage.getItem("username");
const userEmail = localStorage.getItem("email");
const environmentSet = process.env.REACT_APP_SET_SERVER;

export function baseURL() {
  // let env = environmentSet || "local";
  // // let env = "local";
  // switch (env) {
  //   case "local":
  //     console.log("local");
  //     return "http://localhost:3001";
  //   case "staging":
  //     console.log("stagging");
  //     return "http://3.130.68.140:6001";
  //   case "production":
  //     console.log("production");
  //     return "https://api.omegaclients.com"; // + temp;
  //   default:
  //     console.log("Environment not set");
  //     return "http://localhost:3001";
  // }
  return "https://api.omegaclients.com"; // + temp;
  // return "http://3.130.68.140:6001"; // + temp ;
  // http: return "https://d1h2h5stlnyiz8.cloudfront.net"; // + temp;
  // return "http://localhost:3001";
}
// export const baseURL = 'http://3.130.68.140:' +  localStorage.getItem('isDevModeEnabled') == true ? + '6001' : '7001';
// export const baseURL = 'http://localhost:3001';

export function calculateZoneSpecificTime(offset, date = "") {
  // create Date object for current location
  var d = new Date();
  if (date !== "") d = new Date(date);

  if (d.getMonth() <= 10 && d.getUTCDate() <= 3) {
    offset = offset + 1;
  }
  // convert to msec
  // add local time zone offset
  // get UTC time in msec
  let utc = d.getTime() + d.getTimezoneOffset() * 60000;
  // create new Date object for different city
  // using supplied offset
  let nd = new Date(utc + 3600000 * offset);
  return nd;
}
export function isValidPhoneNumber(phoneNumber) {
  if (phoneNumber == null || phoneNumber == "") {
    return false;
  }
  if (phoneNumber.length > 17) {
    return false;
  }
  phoneNumber = phoneNumber.trim();
  var phoneNumberRegex = /^(?:(?:00|\+))[(]{0,1}[1-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  //
  return phoneNumberRegex.test(phoneNumber);
}
export function isValidEmail(email) {
  email = email.toLowerCase().trim();
  var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //
  return emailRegex.test(email);
}
export function isValidName(name) {
  if (name == null) {
    return false;
  }
  name = name.trim();
  var nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  //
  return nameRegex.test(name);
}

export function secretUserSequenceFilter(seqs) {
  var allSeq = seqs;
  var userEmail = localStorage.getItem("email");
  var userId = localStorage.getItem("userId");
  const secretUser = localStorage.getItem("secretUsersArray");
  const secretUsers = JSON.parse(secretUser);
  const isUserSecret = secretUsers.find((user) => user?.user === userEmail);

  if (isUserSecret) {
    let FilteredSeq = allSeq?.filter((seq) => seq.creatorId === userId);
    allSeq = FilteredSeq;
  } else if (!isUserSecret && userEmail != "info@omegamasonry.com") {
    let FilteredSeq = allSeq?.filter((seq) => seq.creatorId != userId);
    allSeq = FilteredSeq;
  } //else if (userEmail === "info@omegamasonry.com") allSeq = allSeq;
  else return allSeq;
}

export function secretUserLeadFilter(leads) {
  var allLeads = leads;
  var userEmail = localStorage.getItem("email");
  const secretUser = localStorage.getItem("secretUsersArray");
  const secretUsers = JSON.parse(secretUser);
  const isUserSecret = secretUsers.find((user) => user?.user === userEmail);
  const secretNames = secretUsers.map(({ name }) => name);

  if (isUserSecret) {
    let FilteredLeads = allLeads?.filter((lead) => {
      return lead?.assigned_to === isUserSecret?.name;
    });
    return FilteredLeads;
  } else if (!isUserSecret && userEmail != "info@omegamasonry.com") {
    let FilteredLeads = allLeads?.filter((lead) => !secretNames.includes(lead?.assigned_to));
    return FilteredLeads;
  } else if (userEmail === "info@omegamasonry.com") {
    return allLeads;
  }
  return allLeads;
}

export function secretUserCredentials(users) {
  const secretUser = users?.filter((user) => user.role === "secret");
  const secretUserData = secretUser?.map(({ name, user }) => ({
    name,
    user,
  }));
  localStorage.setItem("secretUsersArray", JSON.stringify(secretUserData));
  return secretUserData;
}
export function secretUserCheck1(users) {
  const cred = secretUserCredentials(users);
  var allusers = users;
  var userEmail = localStorage.getItem("email");
  const creds = cred.find((user) => user?.user === userEmail);
  if (userEmail === creds?.user) {
    let filterUsers = allusers?.filter((user) => user.user === creds?.user || user.user === "info@omegamasonry.com");
    return filterUsers;
  } else if (userEmail != creds?.user && userEmail !== "info@omegamasonry.com") {
    let filterUsers = allusers?.filter((user) => user.role !== "secret");
    return filterUsers;
  } else return allusers;
}
export function secretUserCheck(users) {
  const isDeveloper = localStorage.getItem("isDeveloper");
  const secretUser = localStorage.getItem("secretUsersArray");
  const secretUsers = JSON.parse(secretUser);
  var userEmail = localStorage.getItem("email");
  const secretUserEmails = secretUsers.map(({ email }) => email);
  const isUserSecret = secretUsers.find((user) => user?.user === userEmail);
  var allusers =
		isDeveloper.toString() === "false"
			? users.filter((user) => !user.isDeveloper)
			: users;
  if (isUserSecret) {
    let filterUsers = allusers?.filter((user) => user.value === isUserSecret?.user || user.value === "info@omegamasonry.com");
    allusers = filterUsers;
  } else if (!isUserSecret && userEmail !== "info@omegamasonry.com") {
    let filterUsers = allusers?.filter(
      (user) => !secretUserEmails.includes(user.value) //user.value !== isUserSecret?.user
    );
    allusers = filterUsers;
  } else if (userEmail === "info@omegamasonry.com") allusers = allusers;
  return allusers;
}
export function phoneNumberConvert(phone) {
  var cellNo;
  cellNo = phone ? phone : "";
  var cellNo = cellNo.replace(/\D/g, "");
  var index = 3;
  var result = cellNo.slice(0, index) + "-" + cellNo.slice(index);
  index = 7;
  cellNo = result.slice(0, index) + "-" + result.slice(index);
  return cellNo;
}
export function shadeColor(col, amt) {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const LOCATIONS_LIST_API = {
  GET: "",
  POST: "",
};
export const capitalize = (string) => {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  // var splitStr = string.toLowerCase().split(' ');
  // for (var i = 0; i < splitStr.length; i++) {
  //   splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  // }
  // return splitStr.join(' ');
  // string.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
  // return (string.charAt(0).toUpperCase() + string.slice(1));
};
export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else return;
};
export const capitalizeFirstLetteronFilter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
};
export const replaceText = (text, replacement) => {
  const wordToReplace = "@@";
  const wordToReplace1 = "##";

  const newText = text?.replace(wordToReplace, replacement?.split(" ")[0]);
  return newText;
};
const isHTML = (text) => {
  const htmlTagRegex = /<[a-z][\s\S]*>/i;
  return htmlTagRegex.test(text);
};
export const IsTextHTML = (text) => {
  const htmlTagRegex = /<[a-z][\s\S]*>/i;
  return htmlTagRegex.test(text);
};
// ${item.item_type == "note" ? `<span style="margin-bottom: 1px; padding: 0px;font-size:12px;line-height: 0.4;>${item.note}</span>` : ""}

export const populateHtml = (timeLineFilterData, leadData, receiverName) => {
  const { name, phone_number, email, svc_requested, address } = leadData;
  const Actions = {
    note: "Notes",
    starred: "Added to Hotlist",
    unstarred: "Removed from Hotlist",
    assigned: "Assignee Changed",
    sold: "Lead Sold",
    "un-sold": "Lead Un-Sold",
    "Lead received": "Lead Received",
    "un assigned": "Lead Un-Assigned",
    "canned email": "Canned Email Sent",
    "lead status": "Lead Status Updated",
    bid: "Bid Sent",
    "canned message": "Canned Message Sent",
    "review message": "Review Message Sent",
    "referral message": "Referral Message Sent",
    "reminder added": "Reminder Added",
    "reminder updated": "Reminder Updated",
    "reminder removed": "Reminder Removed",
  };

  let emailHtml = `
  <html>
  <head>
    <style>
      .item-container {
        margin:0px;
        padding: 0px;
        border: 1px solid #ccc;
      }
      .bullet-list {
        background-color:green;
         list-style-type: square; 
         padding-left: 200px;
       }
       .bullet-list li {
        padding-left: 200px;
        background-color:green;
         color: red; 
       }
    </style>
  </head>
    <body style="margin: 0px;
    padding: 0px;
    line-height: 1;">

    <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 2;"> Hi ${receiverName}, following details have been shared with you, through the LMS system:</p>

      <h5 style="margin: 0px;
      padding: 0px;
      line-height: 0.1;">Lead Information</h5>
      <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 0.1;"><strong>Name :</strong> ${name}</p>
      <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 0.1;"><strong>Phone :</strong> ${phone_number}</p>
      <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 0.1;"><strong>Email :</strong> ${email}</p>
      <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 1;"><strong>Address :</strong> ${address}</p>
      <p style="margin-bottom: 1px; padding: 0px;font-size:13px; line-height: 1;"><strong>Service Requested :</strong> ${svc_requested}</p>
      
      <p style="margin-vertical: 5px;
      padding: 0px;
      line-height: 1;text-decoration: underline;font-size:15px;font-weight:"bold">Action's timeline</p>
    
`;

  timeLineFilterData.forEach((item) => {
    if (item.value && item.value !== "") {
      const isItemHTML = isHTML(item.note ? item.note.replace("<p>", "").replace("</p>", "") : "plain text");
      emailHtml += `
    <div style="margin-bottom: 0px; padding: 0px;line-height: 1;display:flex;flex-direction:row;align-items:flex-end;"><div style="margin-bottom: 0px; padding: 0px 0px 0px 0px;font-size:13px;line-height: 1;"><strong>Action :</strong> ${
      Actions[item.item_type]
    }</div>
    <p style="margin:0px;line-height:1;font-size:10px;padding: 3px 0px 0px 2px;">(${moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")})</p></div>  
       ${
         item.item_type == "note" && isItemHTML
           ? `<div style="margin: 0px; padding: 0px 0px 0px 12px;font-size:13px;line-height:1">${item.note}</div>`
           : item.item_type == "note" && !isItemHTML
           ? `<div style="margin: 0px; padding: 0px 0px 0px 52px;font-size:13px;line-height:1">${item.note}</div>`
           : ""
       }
  `;
    }
  });

  emailHtml += `
    </body>
  </html>
`;
  return emailHtml;
};

export function CarouselSetting(items) {
  let slidesToShow = 1;

  if (items?.length >= 3) {
    slidesToShow = 3; // Set the number of slides to show when there are 3 or more items
  } else {
    slidesToShow = items?.length; // Show the exact number of items when there are fewer than 3
  }

  const settings = {
    dots: false,
    // infinite: infinite={data.length > 4},
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return settings;
}
export function addUserNameEmail(user, type) {
  var temp = type == "SMS" ? [...user.auto_followup_canned_sms_temp] : [...user.auto_followup_canned_email_temp];
  var templateArray = [];
  for (var i = 0; i < temp.length; i++) {
    var obj = {
      label: temp[i].label,
      value: temp[i].value,
      temp_id: temp[i].temp_id,
      updated_at: temp[i].updated_at ? temp[i].updated_at : null,
      user: user.name,
      email: user.user,
    };
    templateArray.push(obj);
  }
  return templateArray;
}

export function addUserNameEmailConcate(user, type) {
  var temp = type == "SMS" ? [...user.auto_followup_canned_sms_temp] : [...user.auto_followup_canned_email_temp];
  var templateArray = [];
  for (var i = 0; i < temp.length; i++) {
    var obj = {
      label: temp[i].label + `-(${user.name})`,
      value: temp[i].value,
      temp_id: temp[i].temp_id,
      user: user.name,
      email: user.user,
      attachment: temp[i].attachment ? temp[i].attachment : null,
    };
    templateArray.push(obj);
  }
  return templateArray;
}

export function addUserToTempObjConcate(temp) {
  if (temp) {
    var templateArray = [];
    for (var i = 0; i < temp.length; i++) {
      var obj = {
        label: temp[i].label + `-(${userName})`,
        value: temp[i].value,
        temp_id: temp[i].temp_id,
        user: userName,
        email: userEmail,
        attachment: temp[i].attachment ? temp[i].attachment : null,
      };
      templateArray.push(obj);
    }
    return templateArray;
  } else return null;
}
export function countTrueValues(obj) {
  console.log("obj", obj);
  // Use Object.values to get an array of the object's values
  console.log("length", Object.values(obj).filter((value) => value === true).length);
  return Object.values(obj).filter((value) => value === true).length;
}
export function addUserEmailNewTemp(temp) {
  if (temp) {
    var templateArray = [];
    for (var i = 0; i < temp?.length; i++) {
      var tempObj = {
        label: temp[i].label,
        value: temp[i].value,
        temp_id: temp[i].temp_id,
        updated_at: temp[i].updated_at ? temp[i].updated_at : null,
        name: userName,
        email: userEmail,
      };
      templateArray.push(tempObj);
    }
    return templateArray;
  }
  {
    return null;
  }
}

export function makeArrayAnArrayofObjects(temp) {
  if (temp) {
    var tempArray = [];
    for (var i = 0; i < temp?.length; i++) {
      var tempObj = {
        label: temp[i],
        value: temp[i],
        id: i,
      };
      tempArray.push(tempObj);
    }
    return tempArray;
  }
  {
    return null;
  }
}
export function uniqueArray(arrayOfObjects) {
  const uniqueArray = arrayOfObjects.reduce((accumulator, current) => {
    const duplicateObject = accumulator.find((obj) => obj.temp_id === current.temp_id);
    if (!duplicateObject) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  return uniqueArray;
}

export async function toggleItem(tempArray, item) {
  let newArray = [...tempArray];
  if (!newArray.includes(item)) {
    newArray.push(item);
    return newArray;
  } else {
    newArray.splice(newArray.indexOf(item), 1);
    return newArray;
  }
}
export function filteredSelectedList(allUser, list, selectedList, selectedOption) {
  const compareByUpdatedAt = (a, b) => {
    if (!a.updated_at && !b.updated_at) {
      return 0;
    }
    if (!a.updated_at) {
      return 1;
    }
    if (!b.updated_at) {
      return -1;
    }
    return new Date(b.updated_at) - new Date(a.updated_at);
  };
  let nameFilter = allUser.filter((item) => {
    return item.label == selectedOption;
  });
  if (nameFilter.length > 0) {
    console.log("name filter", selectedOption);
    let filteredList = list.filter((item) => {
      return item.name == selectedOption;
    });
    return filteredList.sort(compareByUpdatedAt);
  } else if (selectedOption == "Selected Templates") {
    const selectedTemp = list.filter((item) => selectedList.includes(item.temp_id));
    return selectedTemp.sort(compareByUpdatedAt);
  } else if (selectedOption == "Unselected Templates") {
    const unSelectedTemp = list.filter((item) => selectedList.every((excludeId) => item.temp_id !== excludeId));
    return unSelectedTemp.sort(compareByUpdatedAt);
  } else {
    console.log("final else");
    return list;
  }
}

export function filteredResponseList(list, selectedOption) {
  if (selectedOption) {
    let filteredList = list.filter((item) => {
      return item.name == selectedOption;
    });
    return filteredList;
  }
  // else if (selectedOption == "Selected Templates") {
  //   const selectedTemp = list.filter((item) => selectedList.includes(item.temp_id));
  //   return selectedTemp
  // }
  // else if (selectedOption == "Unselected Templates") {
  //   const unSelectedTemp = list.filter((item) =>
  //     selectedList.every((excludeId) => item.temp_id !== excludeId))
  //   return unSelectedTemp
  // }
  else {
    return list;
  }
}

export function sortArray(allEmailTemplate, newTemp) {
  const newAddedTemp = newTemp[newTemp.length - 1];

  const obj = {
    label: newAddedTemp.label,
    value: newAddedTemp.value,
    temp_id: newAddedTemp.temp_id,
    user: userName,
    email: userEmail,
  };

  const filteredArray = allEmailTemplate.filter((temp) => {
    return temp.temp_id != newAddedTemp.temp_id;
  });

  filteredArray.push(obj);
  return filteredArray;
}
export function capitalizeName(name) {
  let newName = name.split(" ");
  let firstName = newName[0];
  let secondName = newName[1] ? newName[1] : null;

  if (secondName) {
    secondName = newName[1];
    return firstName[0].toUpperCase() + secondName[0].toUpperCase();
  } else return firstName[0].toUpperCase();
}
export const checkFileExtension = async (file) => {
  if (file) {
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return { allow: true, type: "pdf" };
      case "png":
        return { allow: true, type: "png" };
      case "jpg":
        return { allow: true, type: "jpg" };
      case "gif":
        return { allow: true, type: "gif" };
      default:
        return { allow: false };
    }
  }
};
export const checkFileExtension2 = async (fileName) => {
  if (fileName && fileName !== "") {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    switch (fileExtension) {
      case "pdf":
        return { allow: true, type: "pdf" };
      case "png":
        return { allow: true, type: "png" };
      case "jpg":
        return { allow: true, type: "jpg" };
      case "gif":
        return { allow: true, type: "gif" };
      default:
        return { allow: false };
    }
  }
};

export async function formatDate(date) {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
}
export async function formatDate2(date) {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${mm}-${dd}-${yyyy}`;
}

export async function getFormattedDate(inputDate) {
  const date = new Date(inputDate);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    const getDate = await formatDate2(date);
    return getDate;
  }
}

export async function hasDatePassed(dateString1, dateString2) {
  if (dateString1 !== null && dateString1 !== "") {
    const date1 = new Date(`${dateString1[3]}${dateString1[4]}/${dateString1[0]}${dateString1[1]}/${dateString1[6]}${dateString1[7]}${dateString1[8]}${dateString1[9]}`);
    const date2 = new Date(dateString2);
    return date1 < date2;
  } else return true;
}

export const getReminderLead2 = (leads) => {
  try {
    const today = formatDate2(new Date());
    const reminderLeads = leads.map((lead) => {
      if (!lead.archived) {
        let reminderSet;
        if (lead.reminder) {
          reminderSet = lead?.reminder?.find((rem) => {
            return rem.creator_id === localStorage.getItem("userId") && rem.reminder_date.toString() === today.toString() && lead.assigned_to === localStorage.getItem("username");
          });
          if (reminderSet) {
            return {
              temp_color: "#FFB659",
              reminderSet: true,
              reminderToShow: reminderSet,
              ...lead,
            };
          }
        }
      }
    });
    const reminderLeads2 = reminderLeads.filter((element) => element !== undefined && element !== null);
    return reminderLeads2;
  } catch (error) {
    console.log(error);
  }
};

const getReminderLeads = async (leads, users) => {
  try {
    const today = await formatDate2(new Date());
    const reminderLeads = leads?.map((lead) => {
      if (!lead?.archived) {
        let reminderSet;
        if (lead?.reminder) {
          reminderSet = lead?.reminder?.find((rem) => {
            return rem.creator_id === localStorage.getItem("userId") && rem.reminder_date.toString() === today.toString() && lead.assigned_to === localStorage.getItem("username");
          });
          if (reminderSet) {
            return {
              temp_color: "#FFB659",
              reminderSet: true,
              reminderToShow: reminderSet,
              ...lead,
            };
          }
        }
      }
    });
    const reminderLeads2 = await reminderLeads?.filter((element) => element !== undefined && element !== null);
    return reminderLeads2;
  } catch (error) {
    console.log(error);
  }
};
export const FilterRemindersForUser = async (allLeads, reminderLeads, users) => {
  try {
    const reminderLeads1 = await allLeads?.filter((item1) => !reminderLeads?.some((item2) => item1._id === item2._id));
    const reminderLeads2 = await reminderLeads1?.map((lead) => {
      let reminderSet;
      if (lead.reminder) {
        reminderSet = lead.reminder?.find((rem) => {
          return (
            (rem.creator_id === localStorage.getItem("userId") && lead.assigned_to === localStorage.getItem("username")) ||
            (localStorage.getItem("isAdmin").toLowerCase() === "admin" && rem.created_by && lead.assigned_to === rem.created_by)
          );
        });
        if (reminderSet) {
          return {
            reminderSet: true,
            reminderToShow: reminderSet,
            ...lead,
          };
        } else return lead;
      }
    });
    const reminderLeads3 = await reminderLeads2?.filter((element) => element !== undefined && element !== null);
    return reminderLeads3;
  } catch (error) {
    console.log(error);
  }
};
const checkRemindersForUser2 = async (allLeads, reminderLeads, users) => {
  try {
    const reminderLeads1 = await allLeads?.filter((item1) => !reminderLeads?.some((item2) => item1?._id === item2?._id));

    const reminderLeads2 = await reminderLeads1?.map((lead) => {
      let reminderSet;
      if (lead?.reminder) {
        reminderSet = lead?.reminder?.find((rem) => {
          return (
            (rem?.creator_id === localStorage.getItem("userId") && lead?.assigned_to === localStorage.getItem("username")) ||
            (localStorage.getItem("isAdmin").toLowerCase() === "admin" && rem?.created_by && lead?.assigned_to === rem?.created_by)
          );
        });
        if (reminderSet) {
          return {
            reminderSet: true,
            reminderToShow: reminderSet,
            ...lead,
          };
        } else if (reminderSet === undefined && lead?.reminderSet) {
          console.log("in new condition - discard properties");
          // Return a copy of the lead without `reminderSet` and `reminderToShow`
          const { reminderSet, reminderToShow, ...newLead } = lead;
          return newLead;
        }

        return lead;
      }
    });

    const reminderLeads3 = await reminderLeads2?.filter((element) => element !== undefined && element !== null);

    return reminderLeads3;
  } catch (error) {
    console.log(error);
  }
};

const checkRemindersForUser = async (allLeads, reminderLeads, users) => {
  // console.log("allLeads", allLeads, reminderLeads);
  try {
    const reminderLeads1 = await allLeads.filter((item1) => !reminderLeads.some((item2) => item1._id === item2._id));
    const reminderLeads2 = await reminderLeads1.map((lead) => {
      let reminderSet;
      if (lead.reminder) {
        reminderSet = lead.reminder?.find((rem) => {
          return (
            (rem.creator_id === localStorage.getItem("userId") && lead.assigned_to === localStorage.getItem("username")) ||
            (localStorage.getItem("isAdmin").toLowerCase() === "admin" && rem.created_by && lead.assigned_to === rem.created_by)
          );
        });
        if (reminderSet) {
          return {
            reminderSet: true,
            reminderToShow: reminderSet,
            ...lead,
          };
        } else return lead;
      }
    });
    // console.log("reminderLeads2==>", reminderLeads2);
    const reminderLeads3 = await reminderLeads2.filter((element) => element !== undefined && element !== null);
    return reminderLeads3;
  } catch (error) {
    console.log(error);
  }
};
const checkRemindersForLoggedUser = async (allLeads, reminderLeads, users) => {
  try {
    const reminderLeads1 = await allLeads.filter((item1) => !reminderLeads.some((item2) => item1._id === item2._id));
    const reminderLeads2 = await reminderLeads1.map((lead) => {
      let reminderSet;
      if (lead.reminder.length > 0) {
        reminderSet = lead.reminder?.find((rem) => {
          return (
            (rem.creator_id === localStorage.getItem("userId") && lead.assigned_to === localStorage.getItem("username")) ||
            (localStorage.getItem("isAdmin").toLowerCase() === "admin" && rem.created_by && lead.assigned_to === rem.created_by)
          );
        });
        if (reminderSet) {
          return {
            reminderSet: true,
            reminderToShow: reminderSet,
            ...lead,
          };
        } else return lead;
      }
    });
    const reminderLeads3 = await reminderLeads2.filter((element) => element !== undefined && element !== null);
    return reminderLeads3;
  } catch (error) {
    console.log(error);
  }
};

export async function updateReminderLead(leads, users) {
  // console.log("lead screen");
  let newLeads = [...leads];
  const reminderLeads = await getReminderLeads(newLeads, users);
  const reminderLeads2 = await checkRemindersForUser2(newLeads, reminderLeads, users);

  const sortedDataTodayLeads = await reminderLeads.sort((a, b) => a.utc_time_stamp + b.utc_time_stamp);
  const sortedData = await reminderLeads2.sort((a, b) => a.utc_time_stamp + b.utc_time_stamp);
  if (sortedDataTodayLeads.length !== 0) {
    let lastObject = sortedDataTodayLeads.pop();
    lastObject.first = true;
    sortedDataTodayLeads.push(lastObject);
  }
  const sortLeads = [...sortedDataTodayLeads, ...sortedData];
  // const secretFilteredLeads = secretUserLeadFilter(sortLeads);
  return sortLeads;
}

export const updateReminderLeadHotlist = async (leads, users) => {
  // console.log("hotlist");
  let newLeads = [...leads];

  const reminderLeads = await getReminderLeads(newLeads, users);
  const reminderLeads2 = await checkRemindersForUser(newLeads, reminderLeads, users);

  const sortedData = await reminderLeads2.sort((a, b) => a.utc_time_stamp - b.utc_time_stamp);
  const sortedDataTodayLeads = await reminderLeads.sort((a, b) => a.utc_time_stamp - b.utc_time_stamp);
  if (sortedDataTodayLeads.length !== 0) {
    sortedDataTodayLeads[0].first = true;
  }
  const sortLeads = [...sortedData, ...sortedDataTodayLeads];
  const sortLeads2 = sortLeads.filter((lead) => lead.starred_by_user_ids);

  // const secretFilteredLeads = secretUserLeadFilter(sortLeads2);
  return sortLeads2;
};
//ma
export const updateReminderLeadData = async (leads, users) => {
  // console.log("reminder");
  let newLeads = [...leads];

  const reminderLeads = await getReminderLeads(newLeads, users);
  var reminLeads = newLeads?.filter((lead) => {
    return lead.reminder.length > 0;
  });
  const reminderLeads2 = await checkRemindersForUser2(newLeads, reminderLeads, users);
  const sortedData = await reminderLeads2.sort((a, b) => a.utc_time_stamp - b.utc_time_stamp);
  const sortedDataTodayLeads = await reminderLeads.sort((a, b) => a.utc_time_stamp - b.utc_time_stamp);
  if (sortedDataTodayLeads.length !== 0) {
    sortedDataTodayLeads[0].first = true;
  }
  const sortLeads = [...sortedData, ...sortedDataTodayLeads];

  const sortLeads2 = sortLeads.filter((lead) => lead.reminderSet);
  // const secretFilteredLeads = secretUserLeadFilter(sortLeads2);
  // console.log("secretFilteredLeads", secretFilteredLeads);
  return sortLeads2;
};

// export const convertHTMLToPlainText = (html) => {
// 	console.log(html);

// 	const tempElement = document.createElement("div");
// 	tempElement.innerHTML = html;
// 	return tempElement.textContent || tempElement.innerText || "";
// };

export const convertHTMLToPlainText = (html) => {
  // Add a space after every closing tag (e.g., </p>, </div>)
  const spacedHtml = html.replace(/<\/[^>]+>/g, "$& ");

  const tempElement = document.createElement("div");
  tempElement.innerHTML = spacedHtml;

  // Extract plain text from the HTML element
  const plainText = tempElement.textContent || tempElement.innerText || "";

  // Trim any extra spaces and return the result
  return plainText.replace(/\s+/g, " ").trim();
};

export const darkenHexColor = (hex, percent) => {
  hex = hex.replace(/^#/, "");
  // Parse hex into RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  // Darken the color
  const darkenedR = Math.round(r * (1 - percent));
  const darkenedG = Math.round(g * (1 - percent));
  const darkenedB = Math.round(b * (1 - percent));
  // Convert back to hex
  const darkenedHex = ((darkenedR << 16) + (darkenedG << 8) + darkenedB).toString(16);
  return "#" + ("000000" + darkenedHex).slice(-6);
};

export const isColorDark = (color) => {
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);
  const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return parseInt(brightness) < 200;
};

export const changeLeadData = async (allLeads, leadId, changeType, newChangedData, users) => {
  const updatedLeads = await dataChangeForLead(allLeads, leadId, changeType, newChangedData);
  if (changeType === "reminder" || changeType === "deleteReminder") {
    const updates = await remindersUpdateLeads(updatedLeads, users);
    return updates;
  } else {
    console.log("default", changeType);
    return updatedLeads;
  }
};
//ma
export const remindersUpdateLeads = async (updatedLeads, users) => {
  console.log("remindersUpdateLeads after 4");
  const LeadScreen = await updateReminderLead(updatedLeads, users);
  // console.log("LeadScreen outer", LeadScreen);
  const StarScreen = await updateReminderLeadHotlist(updatedLeads, users);
  // console.log("StarScreen outer", StarScreen);

  const ReminderScreen = await updateReminderLeadData(updatedLeads, users);
  // console.log("ReminderScreen outer", ReminderScreen);

  return {
    LeadScreen: LeadScreen,
    StarScreen: StarScreen,
    ReminderScreen: ReminderScreen,
  };
};

export const dataChangeForLead = async (allLeads, leadId, changeType, newChangedData) => {
  console.log("dataChangeForLead", leadId, changeType);
  let temp = [...allLeads];
  let arraytofind = temp.findIndex((item) => item._id === leadId);
  console.log("arraytofind", arraytofind);
  let tempObj = { ...temp[arraytofind] };
  if (tempObj) {
    if (changeType === "deleteReminder" || changeType === "reminder") {
      const rem = await forReminderUpdates(changeType, tempObj, newChangedData);
      temp[arraytofind] = rem;
      // console.log("temp[arraytofind]", temp[arraytofind]);
      return { leads: temp, tempObj: tempObj };
    } else {
      Object.keys(newChangedData).forEach((key) => {
        tempObj[key] = newChangedData[key];
      });
      temp[arraytofind] = tempObj;
      return { leads: temp, tempObj: tempObj };
    }
  }
  return;
};
// export const dataChangeForLead = async (
// 	allLeads,
// 	leadId,
// 	changeType,
// 	newChangedData
// ) => {
// 	let temp = [...allLeads];
// 	let tempObj = "";
// 	let arraytofind = temp.findIndex((item) => item._id == leadId);
// 	if (!arraytofind) {
// 		let tempLead = temp.filter((lead) => {
// 			return lead._id == leadId;
// 		});
// 		if (tempLead) {
// 			tempObj = tempLead;
// 			console.log("lead not found to update", tempLead);
// 		} else {
// 			console.log("lead not found to update");
// 			return;
// 		}
// 	} else {
// 		tempObj = { ...temp[arraytofind] };
// 	}
// 	// console.log("tempObj-3", tempObj);
// 	if (tempObj) {
// 		console.log("changeType", changeType);
// 		if (changeType === "deleteReminder" || changeType === "reminder") {
// 			const rem = await forReminderUpdates(
// 				changeType,
// 				tempObj,
// 				newChangedData
// 			);
// 			temp[arraytofind] = rem;
// 			// console.log("temp[arraytofind]", temp[arraytofind]);
// 			return { leads: temp, tempObj: tempObj };
// 		} else {
// 			console.log("else");
// 			Object.keys(newChangedData).forEach((key) => {
// 				tempObj[key] = newChangedData[key];
// 			});
// 			temp[arraytofind] = tempObj;
// 			return { leads: temp, tempObj: tempObj };
// 		}
// 	}
// 	return;
// };

const forReminderUpdates = async (changeType, tempObj, newChangedData) => {
  const { ["reminderToShow"]: omited, ["reminderSet"]: omitted, ["temp_color"]: om, ["first"]: omm, ...result } = tempObj;
  let updatedReminder = [];
  if (changeType === "deleteReminder") {
    updatedReminder = tempObj["reminder"]?.filter((reminder) => reminder.reminderId !== newChangedData.reminderId);
  } else if (changeType === "reminder") {
    if (tempObj["reminder"]?.length !== 0) {
      let isReminderAvailable = tempObj["reminder"]?.some((rem) => rem.creator_id === newChangedData.reminder.creator_id);
      if (isReminderAvailable) {
        updatedReminder = tempObj["reminder"]?.map((reminder) => (reminder.creator_id === newChangedData.reminder.creator_id ? newChangedData.reminder : reminder));
      } else updatedReminder = [...tempObj["reminder"], newChangedData?.reminder];
    } else updatedReminder = [...tempObj["reminder"], newChangedData?.reminder];
  }
  tempObj = { ...result };
  tempObj["reminder"] = updatedReminder;
  return tempObj;
};
