import React, { useEffect } from "react";

const NotificationHandler = () => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data?.type === "NEW_NOTIFICATION") {
          // Play sound for the notification
          const sound = new Audio("/notification-alert.wav"); // Path to your sound file
          sound.play().catch((error) => console.error("Error playing sound:", error));
        }
      });
    }
  }, []);
  console.log("NotificationHandler");
  return null; // This component doesn't render anything
};

export default NotificationHandler;
